<template>
	<breadcrumb  :home="home" :model="britems" />

	<div class="grid">
		

		<div class="col-12">
			<div class="card">
				<Toast/>
				
				<DataTable ref="dt" :loading="loading2" :value="products" v-model:selection="selectedProducts" dataKey="id"  responsiveLayout="stack">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Roster Events</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
								<Button label="Refresh" icon="pi pi-refresh" class="ml-2 p-button-info" @click="shuffle()"  />

                                <Button label="Export" icon="pi pi-upload" class="ml-2 p-button-help" @click="exportCSV($event)"  />


                            </span>
							

						</div>
					</template>

					<Column field="id" header="Id" headerStyle="width:10%;max-width:2rem;"/>
					
					<Column field="email1" header="Person 1" >
					</Column>
					<Column field="email2" header="Person 2"  >
					</Column>
					<Column  header="Event Status" >
						<template #body="slotProps">
							<span class="p-column-title">Status</span>
							{{slotProps.data.event_microsoft_id=='error'?"Error":slotProps.data.status}}
						</template>
					</Column>
                    <!-- <Column  header="Action">
						<template #body="slotProps">
							<Button v-if="!slotProps.data.roster_created" :disabled="!slotProps.data.event_microsoft_id || slotProps.data.event_microsoft_id==null || slotProps.data.event_microsoft_id=='error'" label="Reponses" icon="pi pi-user" class="p-button-rounded  mr-2" @click="redirectToResponses(slotProps.data)"/>

						</template>
					</Column> -->
				</DataTable>

			</div>
		</div>
	</div>

</template>

<script>
import SNService from "../../../service/SNService";
import  'primevue/breadcrumb';

export default {
	props: {
    name1: {
      type: String,
      default: 'Vue!'
    }
  },
	data() {
		return {
			home: {icon: 'pi pi-home', to: '/'},
            britems: [
                {label: 'Manage Events', to: '/sn'},
				{label: 'Roster',disabled:true},
            ],
			event_id:null,
			products: null,
			productDialog: false,
			deleteProductDialog: false,
			deleteReminderDialog: false,
			deleteProductsDialog: false,
			product: {},
			selectedProducts: null,
			filters: {},
			submitted: false,
			account: undefined,
			loading2 :true
		}
	},
	productService: null,
	async created() {
		this.srateService = new SNService();
		//this.initFilters();
	},
	mounted() {
		console.log(this.$route.params);
		this.srateService.rosterApproved(this.$route.params.id).then((data) => {
                this.products = data.data
                this.loading2 = false;
                this.new_disabled=false;
            });	
				
	},
	methods: {
            redirectToResponses(data) {this.$router.push({ path: '/smres/onetone/'+this.$route.params.id+'/'+data.event_microsoft_id });},

            shuffle(){
                this.loading2 = true;
                this.srateService.rosterApproved(this.$route.params.id).then((data) => {
                    this.products = data.data
                    this.loading2 = false;
                    this.new_disabled=false;
                });	
            },
			exportCSV() {
				this.$refs.dt.exportCSV();
			},		
		},	
		
		
		
		
	}

</script>

<style scoped lang="scss">
</style>
